import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Grid from "@mui/material/Grid";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Route, Routes } from "react-router-dom";

function Header(props) {
  const { userName } = props;

  return (
    <React.Fragment>
      <AppBar component="div" color="primary" position="static" elevation={0} sx={{ zIndex: 0 }}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Routes>
                <Route
                  index
                  element={
                    <Typography color="inherit" variant="h5" component="h1">
                      Home
                    </Typography>
                  }
                />
                <Route
                  path="/roster"
                  element={
                    <Typography color="inherit" variant="h5" component="h1">
                      Roster
                    </Typography>
                  }
                />
                <Route
                  path="/weeklyua"
                  element={
                    <Typography color="inherit" variant="h5" component="h1">
                      Weekly UA
                    </Typography>
                  }
                />
              </Routes>
            </Grid>
            <Grid item>
              <Typography>{userName}</Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}

Header.propTypes = {
  onDrawerToggle: PropTypes.func.isRequired,
};

export default Header;
