import { Backdrop, Box, Button, CircularProgress, IconButton } from "@mui/material";
import * as React from "react";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { GenerateUA } from "./GenerateUA";
import { useEffect, useState } from "react";
import { PDFDocument } from "pdf-lib";
import { DataGrid } from "@mui/x-data-grid";
import { collection, getDocs, query, orderBy } from "firebase/firestore";
import { db } from "../firebase";
import dayjs from "dayjs";

const columns = [
  {
    field: "date",
    headerName: "UA List Date",
    flex: 1,
    renderCell: (e) => dayjs(e.row.date.toDate()).format("MMM DD, YYYY"),
  },
  {
    field: "action",
    headerName: "View List",
    width: 90,
    renderCell: (e) => (
      <IconButton
        aria-label="delete"
        color="primary"
        onClick={() => {
          displayPDF(e.row.pdf);
        }}
      >
        <PictureAsPdfIcon />
      </IconButton>
    ),
  },
];

const displayPDF = (duris) => {
  PDFDocument.load(duris).then((doc) => {
    doc.save().then((ba) => {
      var file = new Blob([ba], { type: "application/pdf;base64" });
      var fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    });
  });
};

export const WeeklyUA = () => {
  const [genUAOpen, setGenUAOpen] = useState(false);
  const [busy, setBusy] = useState(false);
  const [rows, setRows] = useState([]);
  useEffect(() => {
    const q = query(collection(db, "uapdfs"), orderBy("date", "desc"));
    getDocs(q)
      .then((r) => {
        const rws = [];
        r.docs.forEach((doc) => {
          rws.push({
            id: doc.id,
            date: doc.data().date,
            pdf: doc.data().pdf,
          });
        });
        setRows(rws);
      })
      .finally(() => {
        setBusy(false);
      });
  }, [genUAOpen]);
  return (
    <>
      <Button
        variant="contained"
        onClick={() => {
          setGenUAOpen(true);
        }}
      >
        Generate New Weekly UA List
      </Button>
      <Box sx={{ width: "400px", marginTop: 2 }}>
        <DataGrid
          disableColumnMenu
          rows={rows}
          columns={columns}
          disableSelectionOnClick
          rowHeight={20}
          headerHeight={20}
          experimentalFeatures={{ newEditingApi: false }}
          autoHeight
          sx={{
            border: 0,
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "primary.main",
              color: "primary.contrastText",
              fontSize: 16,
              "& .MuiDataGrid-columnSeparator": {
                display: "none",
              },
            },
          }}
        />
      </Box>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={busy}
      >
        <CircularProgress color="inherit" />
      </Backdrop>{" "}
      <GenerateUA open={genUAOpen} setOpen={setGenUAOpen} />
    </>
  );
};
