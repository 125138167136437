import * as React from "react";
import Typography from "@mui/material/Typography";
import EditResident from "./EditResident";
import { useEffect, useState } from "react";
import { Backdrop, Box, Button, CircularProgress } from "@mui/material";
import { collection, query, getDocs, writeBatch, doc, Timestamp } from "firebase/firestore";
import { db } from "../firebase";
import { DataGrid } from "@mui/x-data-grid";
import dayjs from "dayjs";

const columns = [
  {
    field: "name",
    headerName: "Name",
    flex: 1,
  },
  {
    field: "house",
    headerName: "House",
    width: 90,
  },
  {
    field: "room",
    headerName: "Room",
    width: 90,
  },
  {
    field: "exempt",
    headerName: "Exempt?",
    width: 90,
  },
  {
    field: "lastua",
    headerName: "Last UA",
    width: 120,
  },
  {
    field: "action",
    headerName: "",
    width: 90,
    renderCell: (e) => (
      <strong>
        <Button
          variant="contained"
          color="warning"
          size="small"
          style={{ padding: 0, fontSize: 10, height: 16 }}
          onClick={() => {
            console.log(e);
            console.log("ID", e.id);
            e.row.setUserData(e.row);
            e.row.setEditorOpen(true);
          }}
        >
          EDIT
        </Button>
      </strong>
    ),
  },
];

export default function Roster({ header }) {
  const [editorOpen, setEditorOpen] = useState(false);
  const [userdata, setUserdata] = useState({});
  const [busy, setBusy] = useState(false);
  const [rows, setRows] = useState([]);
  useEffect(() => {
    //    setBusy(true);
    const q = query(collection(db, "residents"));
    getDocs(q)
      .then((r) => {
        const cap = (s) => s && s[0].toUpperCase() + s.slice(1);
        const rws = [];
        r.docs.forEach((doc) => {
          rws.push({
            id: doc.id,
            name: doc.data().name,
            house: cap(doc.data().house),
            room: doc.data().room,
            exempt: doc.data().exempt ? "Yes" : "No",
            lastua: doc.data().lastua ? dayjs(doc.data().lastua.toDate()).format("MMM DD, YYYY") : "n/a",
            setUserData: setUserdata,
            setEditorOpen: setEditorOpen,
          });
        });
        setRows(rws);
      })
      .finally(() => {
        setBusy(false);
      });
  }, [userdata, editorOpen]);

  if (header) {
    return (
      <>
        <Typography color="inherit" variant="h5" component="h1">
          Roster
        </Typography>
      </>
    );
  }
  return (
    <>
      <Button
        onClick={() => {
          populate();
        }}
        variant="contained"
        sx={{ display: "none" }}
      >
        Populate
      </Button>
      <Button
        onClick={() => {
          setUserdata({});
          setEditorOpen(true);
        }}
        variant="contained"
      >
        Add New Resident
      </Button>
      <Box sx={{ width: "600px", marginTop: 2 }}>
        <DataGrid
          disableColumnMenu
          rows={rows}
          columns={columns}
          disableSelectionOnClick
          rowHeight={20}
          headerHeight={20}
          experimentalFeatures={{ newEditingApi: false }}
          autoHeight
          sx={{
            border: 0,
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "primary.main",
              color: "primary.contrastText",
              fontSize: 16,
              "& .MuiDataGrid-columnSeparator": {
                display: "none",
              },
            },
          }}
        />
      </Box>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={busy}
      >
        <CircularProgress color="inherit" />
      </Backdrop>{" "}
      <EditResident open={editorOpen} setOpen={setEditorOpen} user={userdata} />
    </>
  );
}

const populate = () => {
  const a = [
    { room: 1, name: "Kyle Raynor" },
    { room: 2, name: "Brian Mulcare" },
    { room: 3, name: "Ken Case" },
    { room: 4, name: "Bruce Bearce" },
    { room: 5, name: "Paul Weber" },
    { room: 6, name: "Damon Pope" },
    { room: 7, name: "Mike Bisping" },
    { room: 8, name: "Dan Sherwin" },
    { room: 9, name: "Tony Heath" },
    { room: 10, name: "John Rainey" },
    { room: 11, name: "Douglas Phillips (Animal)" },
    { room: 12, name: "Jimmy Woodburn" },
    { room: 13, name: "Danny McCreachin" },
    { room: 14, name: "Patrick Zwanzig" },
    { room: 15, name: "Mike Durham" },
  ];
  const batch = writeBatch(db);
  a.forEach((v) => {
    batch.set(doc(db, "residents", v.name), { room: v.room, name: v.name, house: "clover", exempt: false });
  });
  batch.commit().then(() => {
    alert("Done");
  });
};
