import React, { useState } from "react";
import Paperbase from "./Paperbase";
import { MemoryRouter, Route, Routes } from "react-router-dom";
import Login from "./login/Login";
import { CookiesProvider, useCookies } from "react-cookie";

function App() {
  const [authenticated, setAuthenticated] = useState(false);
  const [userName, setUserName] = useState("");
  const [cookies, setCookie] = useCookies([authenticated]);
  useState(() => {
    if (cookies.user) {
      setAuthenticated(true);
      setUserName(cookies.user.name);
    } else {
      setAuthenticated(false);
    }
  }, []);

  return (
    <CookiesProvider>
      <MemoryRouter>
        <Routes>
          <Route
            path="/*"
            element={
              authenticated ? (
                <Paperbase
                  userName={userName}
                  setAuthenticated={setAuthenticated}
                />
              ) : (
                <Login
                  setAuthenticated={setAuthenticated}
                  setUserName={setUserName}
                  setCookie={setCookie}
                />
              )
            }
          />
        </Routes>
      </MemoryRouter>
    </CookiesProvider>
  );
}

export default App;
