import * as React from "react";
import {
  Backdrop,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useEffect, useState } from "react";
import { doc, setDoc, deleteDoc } from "firebase/firestore";
import { db } from "../firebase";

export default function EditResident({ open, setOpen, user }) {
  const [house, setHouse] = useState("garfield");
  const [room, setRoom] = useState(0);
  const [exempt, setExempt] = useState(false);
  const [name, setName] = useState("");
  const [busy, setBusy] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  useEffect(() => {
    if (user.name) {
      setHouse(user.house.toLowerCase());
      setRoom(user.room);
      setExempt(user.exempt === "Yes");
      setName(user.name);
    } else {
      setHouse("garfield");
      setRoom(0);
      setExempt(false);
      setName("");
    }
  }, [user]);
  const handleNameChange = (e) => {
    setName(e.target.value);
  };
  const handleHouseChange = (e) => {
    setHouse(e.target.value);
  };
  const handleChange = (event) => {
    setRoom(event.target.value);
  };
  const handleExemptChange = (e) => {
    setExempt(e.target.checked);
  };

  const submit = () => {
    setBusy(true);
    setDoc(doc(db, "residents", name), {
      name: name,
      exempt: exempt,
      room: room,
      house: house,
    })
      .then(() => {
        setExempt(false);
        setName("");
        setHouse("garfield");
        setRoom(0);
        setOpen(false);
      })
      .finally(() => {
        setBusy(false);
      });
  };

  const deleteResident = () => {
    setBusy(true);
    deleteDoc(doc(db, "residents", name))
      .then(() => {
        setExempt(false);
        setName("");
        setHouse("garfield");
        setRoom(0);
        setConfirmDeleteOpen(false);
        setOpen(false);
      })
      .finally(() => {
        setBusy(false);
      });
  };

  return (
    <>
      <Dialog open={open}>
        <DialogTitle>Edit resident</DialogTitle>
        <DialogContent>
          <Stack direction="column" gap={3}>
            <TextField autoFocus fullWidth label="Name" value={name} onChange={handleNameChange} sx={{ marginTop: 2 }} />
            <FormControl>
              <FormLabel id="house-radio-buttons-group-label">House</FormLabel>
              <RadioGroup row defaultValue="garfield" name="house-radio-buttons-group" value={house} onChange={handleHouseChange}>
                <FormControlLabel value="garfield" control={<Radio />} label="Garfield" />
                <FormControlLabel value="clover" control={<Radio />} label="Clover" />
              </RadioGroup>
            </FormControl>

            <FormControl sx={{ width: 120 }}>
              <InputLabel>Room</InputLabel>
              <Select value={room} autoWidth label="Room" onChange={handleChange}>
                <MenuItem key={0} value={0}>
                  n/a
                </MenuItem>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map((v) => {
                  return (
                    <MenuItem key={v} value={v}>
                      {v}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            <FormControlLabel label="Exempt" control={<Checkbox checked={exempt} onChange={handleExemptChange} />} />
          </Stack>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "space-between",
            padding: "0px 25px",
            paddingTop: 1,
            paddingBottom: 2,
          }}
        >
          <Button
            color="error"
            variant="outlined"
            onClick={() => {
              setOpen(false);
            }}
          >
            CANCEL
          </Button>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => {
              setConfirmDeleteOpen(true);
            }}
          >
            DELETE
          </Button>
          <Button color="primary" variant="contained" onClick={submit}>
            SUBMIT
          </Button>
        </DialogActions>
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={busy}
        >
          <CircularProgress color="inherit" />
        </Backdrop>{" "}
      </Dialog>
      <Dialog
        open={confirmDeleteOpen}
        onClose={() => {
          setConfirmDeleteOpen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Use Google's location service?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This will permanently delete this resident out of this system. This cannot be undone. Do you want to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setConfirmDeleteOpen(false);
            }}
          >
            No
          </Button>
          <Button onClick={deleteResident} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
