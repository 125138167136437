import * as React from "react";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import { Backdrop, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { collection, doc, getDocs, query, setDoc, Timestamp, writeBatch } from "firebase/firestore";
import { db } from "../firebase";
import { jsPDF } from "jspdf";
import bottle from "../uabottle.jpg";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import uuid from "react-uuid";

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

export const GenerateUA = ({ open, setOpen }) => {
  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState([]);
  const [right, setRight] = React.useState([]);
  const [resList, setResList] = React.useState({});

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);
  const [busy, setBusy] = useState(false);

  const isDateOlder = (d1, days) => {
    let td = new Date();
    let rd = d1.toDate();
    td.setDate(td.getDate() - (days || 0));
    let islt = rd < td;
    return islt;
  };

  const createLists = (docs) => {
    const reslist = {};
    const eligible = [];
    const noneligible = [];
    const exempt = [];
    docs.forEach((doc) => {
      const data = doc.data();
      reslist[doc.id] = data;
      if (data.exempt) {
        exempt.push(doc.id);
        return;
      }
      if (!data.lastua) {
        eligible.push(doc.id);
        return;
      }
      if (data.name === "Dan Sherwin") {
        if (isDateOlder(data.lastua, 44)) {
          eligible.push(doc.id);
        } else {
          noneligible.push(doc.id);
        }
      } else {
        if (isDateOlder(data.lastua, 16)) {
          eligible.push(doc.id);
        } else {
          noneligible.push(doc.id);
        }
      }
    });
    setResList(reslist);
    const inlist = [];
    const notinlist = [];
    const numinlist = 5;
    if (eligible.length > numinlist) {
      for (let i = 0; i < numinlist; i++) {
        const j = Math.floor(Math.random() * eligible.length);
        inlist.push(eligible[j]);
        eligible.splice(j, 1);
      }
      [...eligible, ...noneligible, ...exempt].forEach((id) => {
        notinlist.push(id);
      });
    } else {
      eligible.forEach((id) => {
        inlist.push(id);
      });
      for (let i = 0; i < numinlist - eligible.length; i++) {
        const j = Math.floor(Math.random() * noneligible.length);
        if (i > noneligible.length - 1) continue;
        inlist.push(noneligible[j]);
        noneligible.splice(j, 1);
      }
      [...noneligible, ...exempt].forEach((id) => {
        notinlist.push(id);
      });
    }
    notinlist.sort();
    inlist.sort();
    setRight(notinlist);
    setLeft(inlist);
  };

  useEffect(() => {
    setBusy(true);
    const q = query(collection(db, "residents"));
    getDocs(q)
      .then((r) => {
        const rws = [];
        r.docs.forEach((doc) => {
          rws.push(doc.data().name);
        });
        createLists(r.docs);
      })
      .finally(() => {
        setBusy(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
  };

  const generatePDF = () => {
    var img = new Image();
    img.src = bottle;
    const pdfdoc = new jsPDF();
    pdfdoc.addImage(img, "jpg", 110, 20, 80, 80);
    pdfdoc.setFontSize(30);
    pdfdoc.setLineHeightFactor(2.5);
    pdfdoc.text(["U.A. List for", selectedDate.format("MMM DD, YYYY")], 30, 50);
    pdfdoc.setFontSize(28);
    pdfdoc.setLineHeightFactor(2);
    pdfdoc.text(left, 20, 150, {});
    pdfdoc.setFontSize(6);
    pdfdoc.text(["v" + window.appversion], 10, 280);
    return pdfdoc;
  };

  // const previewList = () => {
  //   const pdfdoc = generatePDF();
  //   pdfdoc.output("dataurlnewwindow", { filename: "UAListPreview.pdf" });
  // };

  const updateResidents = () => {
    const batch = writeBatch(db);
    setBusy(true);
    left.forEach((id) => {
      batch.update(doc(db, "residents", id), { lastua: Timestamp.fromDate(selectedDate.toDate()) });
    });
    batch.commit().then(() => {});
  };

  const saveAndGenerateList = () => {
    setBusy(true);
    setTimeout(() => {
      updateResidents();
      const pdfdoc = generatePDF();
      setDoc(doc(db, "uapdfs", uuid()), {
        date: Timestamp.fromDate(selectedDate.toDate()),
        pdf: pdfdoc.output("datauristring"),
      })
        .then(() => {})
        .finally(() => {
          pdfdoc.save("UAList_" + selectedDate.format("YYYY-MM-DD") + ".pdf");
          setBusy(false);
          setOpen(false);
        });
    }, 500);
  };

  const customList = (items) => (
    <Paper sx={{ width: "fit-content", height: 400, overflow: "auto" }}>
      <List dense component="div" role="list">
        {items.map((value) => {
          const labelId = `transfer-list-item-${value}-label`;
          let litext = resList[value].name;
          if (resList[value].lastua) {
            litext += " (" + resList[value].lastua.toDate().toISOString().split("T")[0] + ")";
          } else {
            litext += " (n/a)";
          }
          return (
            <ListItem key={value} role="listitem" button onClick={handleToggle(value)} sx={{ paddingTop: 0, paddingBottom: 0 }}>
              <ListItemIcon sx={{ marginRight: 0 }}>
                <Checkbox sx={{ padding: 0, paddingRight: 0.8 }} checked={checked.indexOf(value) !== -1} tabIndex={-1} disableRipple />
              </ListItemIcon>
              <ListItemText
                id={labelId}
                primary={`${litext}`}
                sx={{
                  color: resList[value].exempt ? "red" : "auto",
                  textDecoration: resList[value].exempt ? "line-through" : "none",
                  textDecorationStyle: resList[value].exempt ? "dotted" : "none",
                }}
              />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Paper>
  );

  const [selectedDate, setSelectedDate] = useState(dayjs(new Date().toISOString()));
  const handleDateChange = (e) => {
    setSelectedDate(e);
  };

  return (
    <>
      <Dialog open={open} maxWidth="xl">
        <DialogTitle>Select UA List</DialogTitle>
        <DialogContent>
          <Stack direction="column" gap={2} paddingTop={1} alignItems="flex-start">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="Date of UA"
                value={selectedDate}
                inputFormat="YYYY-MM-DD"
                onChange={handleDateChange}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            <Stack direction="row" justifyContent="space-between" width="100%">
              <Typography fontWeight="bold">Included in List</Typography>
              <Typography fontWeight="bold">Not Included</Typography>
            </Stack>
            <Grid container spacing={2} justifyContent="center" alignItems="center" flexWrap="nowrap" sx={{ width: "fit-content" }}>
              <Grid item>{customList(left)}</Grid>
              <Grid item>
                <Grid container direction="column" alignItems="center">
                  <Button sx={{ my: 0.5 }} variant="outlined" size="small" onClick={handleAllRight} disabled={left.length === 0} aria-label="move all right">
                    ≫
                  </Button>
                  <Button
                    sx={{ my: 0.5 }}
                    variant="outlined"
                    size="small"
                    onClick={handleCheckedRight}
                    disabled={leftChecked.length === 0}
                    aria-label="move selected right"
                  >
                    &gt;
                  </Button>
                  <Button
                    sx={{ my: 0.5 }}
                    variant="outlined"
                    size="small"
                    onClick={handleCheckedLeft}
                    disabled={rightChecked.length === 0}
                    aria-label="move selected left"
                  >
                    &lt;
                  </Button>
                  <Button sx={{ my: 0.5 }} variant="outlined" size="small" onClick={handleAllLeft} disabled={right.length === 0} aria-label="move all left">
                    ≪
                  </Button>
                </Grid>
              </Grid>
              <Grid item>{customList(right)}</Grid>
            </Grid>
          </Stack>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "space-between",
            padding: "0px 25px",
            paddingTop: 1,
            paddingBottom: 2,
          }}
        >
          <Button
            color="error"
            variant="outlined"
            onClick={() => {
              setOpen(false);
            }}
          >
            CANCEL
          </Button>
          <Button color="primary" variant="contained" onClick={saveAndGenerateList}>
            SAVE UA LIST
          </Button>
        </DialogActions>
      </Dialog>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: 99999999,
        }}
        open={busy}
      >
        <CircularProgress color="inherit" />
      </Backdrop>{" "}
    </>
  );
};
