import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import logo from "../SHIP-logo.png";
import { useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import SetPassword from "./SetPassword";
import { CircularProgress } from "@mui/material";
import { blue } from "@mui/material/colors";
import { firebaseAuth } from "../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
const theme = createTheme();

export default function Login({ setAuthenticated, setUserName, setCookie }) {
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  if (location.pathname !== "/" && location.pathname !== "/password") {
    window.location.replace("/");
  }
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setErrorMessage("");
    const data = new FormData(event.currentTarget);
    const login = data.get("login");
    const password = data.get("password");
    signInWithEmailAndPassword(firebaseAuth, login, password)
      .then((r) => {
        setUserName(login);
        setAuthenticated(true);
        setCookie("user", { name: login });
        navigate("/");
      })
      .catch((r) => {
        if (
          r.code === "auth/wrong-password" ||
          r.code === "auth/invalid-email"
        ) {
          setErrorMessage("Invalid email and/or password");
        } else {
          setErrorMessage(r.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Routes>
      <Route
        path="password"
        element={
          <SetPassword
            setAuthenticated={setAuthenticated}
            setUserName={setUserName}
            setCookie={setCookie}
          />
        }
      />
      <Route
        path="/*"
        element={
          <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
              <CssBaseline />
              <Box
                sx={{
                  marginTop: 8,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img src={logo} alt="SHIP" width={150} />
                <Typography component="h1" variant="h5" marginTop={2}>
                  Sign in
                </Typography>
                {errorMessage ? (
                  <Typography color="red">{errorMessage}</Typography>
                ) : (
                  ""
                )}
                <Box
                  component="form"
                  onSubmit={handleSubmit}
                  noValidate
                  sx={{ mt: 1 }}
                >
                  {loading && (
                    <CircularProgress
                      sx={{
                        color: blue[500],
                        position: "absolute",
                        top: "40%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  )}
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="login"
                    label="Email address"
                    name="login"
                    autoComplete="login"
                    autoFocus
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Sign In
                  </Button>
                </Box>
              </Box>
            </Container>
          </ThemeProvider>
        }
      />
    </Routes>
  );
}
