import * as React from "react";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import PeopleIcon from "@mui/icons-material/People";
import { Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import logo from "./SHIP-logo.png";
import { Logout, Man } from "@mui/icons-material";
import { Link, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import { signOut } from "firebase/auth";
import { firebaseAuth } from "./firebase";

const item = {
  py: "2px",
  px: 3,
  color: "rgba(255, 255, 255, 0.7)",
  "&:hover, &:focus": {
    bgcolor: "rgba(255, 255, 255, 0.08)",
  },
};

const itemCategory = {
  boxShadow: "0 -1px 0 rgb(255,255,255,0.1) inset",
  py: 1.5,
  px: 3,
};

export default function Navigator(props) {
  const { setAuthenticated, ...other } = props;
  const location = useLocation();
  const [, , removeCookie] = useCookies();

  const categories = [
    {
      id: "Build",
      children: [
        { id: "Weekly UA", icon: <Man />, to: "/" },
        {
          id: "Roster",
          icon: <PeopleIcon />,
          to: "/roster",
        },
        {
          id: "Logout",
          icon: <Logout />,
          to: "",
          onclick: () => {
            signOut(firebaseAuth).then(() => {
              removeCookie("user");
              setAuthenticated(false);
            });
          },
        },
      ],
    },
  ];

  return (
    <Drawer variant="permanent" {...other}>
      <Stack direction="row" alignItems="center" justifyContent="space-evenly" paddingTop={2}>
        <img alt="Logo" src={logo} width={80} />
        <Typography color="white" fontSize={30}>
          SHIP
        </Typography>
      </Stack>
      <List disablePadding>
        <ListItem sx={{ ...item, ...itemCategory }}></ListItem>
        {categories.map(({ id, children }) => (
          <Box key={id} sx={{ bgcolor: "#101F33" }}>
            {children.map(({ id: childId, icon, to, onclick }) => (
              <Link key={Math.random()} to={to ? to : "/"} style={{ textDecoration: "none" }} onClick={onclick ? onclick : () => {}}>
                <ListItem disablePadding key={childId}>
                  <ListItemButton selected={to === location.pathname} sx={item}>
                    <ListItemIcon>{icon}</ListItemIcon>
                    <ListItemText>{childId}</ListItemText>
                  </ListItemButton>
                </ListItem>
              </Link>
            ))}

            <Divider sx={{ mt: 2 }} />
          </Box>
        ))}
      </List>
    </Drawer>
  );
}
