import * as React from "react";
import { Route, Routes } from "react-router-dom";
import Roster from "./pages/Roster";
import { WeeklyUA } from "./pages/WeeklyUA";

export default function Content() {
  return (
    <Routes>
      <Route index element={<WeeklyUA />} />
      <Route path="/roster" element={<Roster />} />
      <Route path="/weeklyua" element={<WeeklyUA />} />
    </Routes>
  );
}
