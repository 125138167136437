import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import logo from "../SHIP-logo.png";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { blue } from "@mui/material/colors";
import { db } from "../firebase";
import { doc, updateDoc } from "firebase/firestore";

const theme = createTheme();

export default function SetPassword({
  setAuthenticated,
  setUserName,
  setCookie,
}) {
  const [errorMessage, setErrorMessage] = useState("");
  const { login, loginName } = useLocation().state;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorMessage("");
    setLoading(true);
    const data = new FormData(event.currentTarget);
    const pw = data.get("password");
    if (pw !== data.get("confirmpassword")) {
      setErrorMessage("Passwords do not match");
      setLoading(false);
      return;
    }
    if (pw.length < 8) {
      setErrorMessage("Password needs to be at least 8 characters");
      setLoading(false);
      return;
    }
    const docref = doc(db, "users", login);
    await updateDoc(docref, {
      password: pw,
    });
    setLoading(false);
    setUserName(loginName);
    setCookie("user", { name: loginName }, { maxAge: 60 * 60 * 2 });
    setAuthenticated(true);
    navigate("/");
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src={logo} alt="SHIP" width={150} />
          <Typography component="h1" variant="h5" marginTop={2}>
            Set a password for {login}
          </Typography>
          {errorMessage ? (
            <Typography color="red">{errorMessage}</Typography>
          ) : (
            ""
          )}
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            {loading && (
              <CircularProgress
                sx={{
                  color: blue[500],
                  position: "absolute",
                  top: "40%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="confirmpassword"
              label="Confirm Password"
              type="password"
              id="confirmpassword"
              autoComplete=""
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
