import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDCsmlh8sTmXr_jKaMdni4mWPNUDkC-CDo",
  authDomain: "ship-6fa49.firebaseapp.com",
  projectId: "ship-6fa49",
  storageBucket: "ship-6fa49.appspot.com",
  messagingSenderId: "577161102688",
  appId: "1:577161102688:web:b5372f9fe2727b902c0a05",
};

export const firebaseApp = initializeApp(firebaseConfig);
export const firebaseAuth = getAuth(firebaseApp);
export const db = getFirestore(firebaseApp);
